import React, { Component } from 'react'
import "./Menu.css"

import { Container, Row, Col } from 'react-grid-system';

import menu_pdf from '../../shared/bobatouch_menu.pdf';
import Button from 'react-bootstrap/Button';
import webimg2 from '../../images/webimg2.jpg';

import { setConfiguration } from 'react-grid-system';

setConfiguration({ maxScreenClass: 'xl' });

export default class Menu extends Component {

    render() {
        return (
            <div>
                <Container style={{width: "80%"}}>
                    <Row>
                        <Col lg={6} style={{marginTop: "2%"}}>
                            <img 
                                src={webimg2} 
                                style={{
                                    width: "100%",
                                    height: "auto",
                                    margin: 0,
                                    padding: 0
                                }}
                            />
                            <div class="caption">
                                P&G Lemonade
                            </div>
                        </Col>

                        <Col lg={6} style={{display: "flex", alignItems: "center"}}>
                        <div>
                            <div class="about-header">
                                Menu 1
                            </div>

                            <div class="about-content">
                                Enjoy our crowd favorite Grey Milk T., 
                            </div>
                        
                            <div class="body">
                                <div class="menu-body">
                                     <a href={menu_pdf} target="_tab" rel="noopener noreferrer"><Button variant="light">View Menu</Button></a>
                                </div>
                            </div>
                        </div>
                        </Col>
                    </Row>  
                </Container>
            </div>
        )
    }
}
