import React, { Component } from 'react'
import { Container, Row, Col } from 'react-grid-system';
import "./About.css"

import img_interior from '../../images/interior.jpg';
import webimg1 from '../../images/webimg1.jpg';

import menu_pdf from '../../shared/bobatouch_menu.pdf';
import Button from 'react-bootstrap/Button';

export default class About extends Component {
    render() {
        return (
            <div>
                <img 
                    src={img_interior}
                    alt="" 
                    style={{
                        width: "100%",
                        height: "auto",
                        margin: 0,
                        padding: 0
                    }}
                />

                <Container style={{width: "90%"}}>
                    <Row>
                        <Col lg={6} style={{display: "flex", alignItems: "center"}}>
                            <div>
                                <div class="subheader-2" style={{marginTop: "5%"}}>
                                    About Us
                                </div>

                                <div class="about-content">
                                    We are a family owned specialty boba shop. We aim to bring quality and delicious drinks to our customers.
                                </div>

                                <div class="subheader-2">
                                    Menu
                                </div>
                                <div class="menu" style={{paddingBottom: 15}}>
                                     <a href={menu_pdf} target="_tab" rel="noopener noreferrer"><Button variant="light">View Menu</Button></a>
                                </div>
                            
                                <div class="subheader-2">
                                    Hours
                                </div>
                                <div class="body">
                                    <div>
                                        <b>Mon</b>:    11:00am - 8:00pm <br />
                                        <b>Tues</b>:   CLOSED <br />
                                        <b>Wed</b>:    11:00am - 8:00pm <br />
                                        <b>Thur</b>:   11:00am - 8:00pm <br />
                                        <b>Fri</b>:    11:00am - 8:00pm <br />
                                        <b>Sat</b>:    11:00am - 8:00pm <br />
                                        <b>Sun</b>:    11:00am - 8:00pm <br/>
                                    </div>
                                    <div style={{paddingTop: 15, paddingBottom: 15, fontSize: 18}}>
                                        * Hours are subject to change
                                    </div>
                                </div>
                                
                                <div class="subheader-2">
                                    Location
                                </div>
                                <div class="body" style={{marginBottom: 15}}>
                                    3107 E Colfax Ave, Denver, CO 80206
                                </div>
                            </div>
                        </Col>
                        
                        <Col lg={6} style={{marginTop: "2%"}} className="d-none d-lg-block">
                            <img 
                                src={webimg1} 
                                style={{
                                    width: "100%",
                                    height: "auto",
                                    margin: 0,
                                    padding: 0
                                }}
                            />
                            <div class="caption">
                                Tiger Milk
                            </div>
                        </Col>
                    </Row>  
                </Container>
            </div>
        )
    }
}