import React, { Component } from 'react';
import './promo.css';
import Slider from 'infinite-react-carousel';

import promo1 from '../../../images/promos/promo1.png';
import promo2 from '../../../images/promos/promo2.png';
import menu2 from '../../../images/menu2.png';

class Promo extends Component {

    render() {
        return (
            <div id="promo">
                <img src={menu2} class="promoimg"/> 
                {/*
                <Slider 
                    dots
                    autoplay={true}
                    autoplaySpeed={10000}
                    arrows={false}
                    dots={false}
                    initialSlide={true}
                    pauseOnHover={false}
                >
                    <div>
                        <img src={promo1} class="sliderimg" />
                    </div>
                    <div>
                        <img src={promo2} class="sliderimg" />
                    </div>
                </Slider> */}
            </div>
        );
    }
}

export default Promo;