import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink
} from 'react-router-dom';
import Main from './components/Main/Main';
import StaticMenu from './components/tv-display/static-menu/static-menu';
import MenuSlideshow from './components/tv-display/display1/MenuSlideshow';
import Promo from './components/tv-display/promos/promo';

import './App.css';

function App() {
  return (
      <div className="App">
        <Router>
          <Switch>
            {/* Must be in strict order to display and route properly */}
            <Route path="/promo">
              <Promo />
            </Route>
            <Route path="/menuslideshow">
              <MenuSlideshow />
            </Route>
            <Route path="/staticmenu">
              <StaticMenu />
            </Route>
            <Route path="/">
              <Main />
            </Route>
          </Switch>
        </Router>
      </div>
  );
}

export default App;
