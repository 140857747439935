import React, { Component } from 'react';
import './MenuSlideshow.css';
import topFive from '../../../images/menu-imgs/topFive.png';

class MenuSlideshow extends Component {

    render() {
        return (
            <div id="topFive">
                <img src={topFive} class="staticImg"/>
            </div>
        );
    }
}

export default MenuSlideshow;