import React, { Component } from 'react';
import './NavBar.css';
import logo from '../../images/BobaTouch-ShibaFace-Small.png';

import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';

import menu_pdf from '../../shared/bobatouch_menu.pdf';

import { Nav } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

class NavBar extends Component {   
    render() {
        return (        
            <Container>
                <Navbar collapseOnSelect expand="md" className="custom-nav">
                    <Container>
                    <Navbar.Brand href="#home" className="brand-text">
                        <img 
                            src={logo}
                            alt="Boba Touch logo"
                        />
                        Boba Touch
                    </Navbar.Brand>

                    {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" /> */}
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="ms-auto navbar-buttons">
                                <Nav.Link href={menu_pdf} target="_tab" rel="noopener noreferrer">Menu</Nav.Link>
                                <Nav.Link href="#contact">Contact</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </Container>
        );
    }
}

export default NavBar;