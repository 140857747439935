import React, { Component } from 'react'
import "./Contact.css";
import SocialButtonsContainer from 'react-social-media-buttons';

import { Container, Row, Col } from 'react-grid-system';

export default class Contact extends Component {
    render() {
        return (
            <div id="contact">
                <div class="divider"></div>
                <div class="footer">
                    <div class="contact-body">
                        <div style={{display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "5px"}}>
                            <SocialButtonsContainer
                                links={['https://www.facebook.com/BobaTouch','https://www.instagram.com/bobatouch/']}
                                buttonStyle={{width: '42px', height: '42px', margin: '0px 5px', backgroundColor: '#f0f0f0', borderRadius: '20%'}}
                                iconStyle={{color: '#000000'}}
                                openNewTab={true}
                            />
                        </div>
                        <div>
                            support@bobatouch.com
                        </div>
                        <div style={{marginBottom: "48px"}}>
                            (720) 707-4282
                        </div>
                    </div>
                    © 2021 Boba Touch LLC. All Rights Reserved
                </div>
            </div>
        )
    }
}