import React, { Component } from 'react';
import NavBar from '../NavBar/NavBar';
import About from '../About/About';
import Menu from '../Menu/Menu';
import Contact from '../Contact/Contact';

class Main extends Component {
    
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <NavBar />
                {/* <Hero /> */}
                <About />
                {/* <Menu /> */}
                <Contact />
            </div>
        );
    }
}

export default Main;