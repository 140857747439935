import React, { Component } from 'react';
import './static-menu.css';
// import Menu1 from '../../../images/staticmenu.png';
import menu1 from '../../../images/menu1.png';

class StaticMenu extends Component {

    render() {
        return (
            <div id="staticmenu">
                <img src={menu1} class="staticmenuimg"/>
            </div>
        );
    }
}

export default StaticMenu;